import getConfig from 'next/config';
import fetch from "unfetch";

async function logEvent(productVersionId, email, action, source, eventName, props) {
    const nextConfig = getConfig();

    const apiOptions = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        mode: 'same-origin',
        method: 'post',
        body: JSON.stringify({ userEmail: email, source, action, eventName, props }),
    };
    const apiResponse = await fetch(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/admin/product/${productVersionId}/eventWrite`, apiOptions);
    if (apiResponse.ok) {
        const log = await apiResponse.text();
        console.log(log);
    } else {
        console.log('error during logging');
    }
}

async function searchEvents(productVersionId) {
    const nextConfig = getConfig();
    let apiResponse = await fetch(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/admin/product/${productVersionId}/eventsRead?source=mcc-manager`);
    if (apiResponse.ok) {
        const data = await apiResponse.json();
        return data;
    } else if (apiResponse.status === 404) {
        console.log('api responded with zero results');
        return [];
    } else {
        console.error('api response not ok');
    }
}

export { logEvent, searchEvents };
