import getConfig from 'next/config';
import Router from 'next/router';
import FormData from 'form-data';
import { logEvent } from './EventLogger';
import NProgress from 'nprogress';
import { fetcher } from './utils';
import fetch from 'unfetch';

async function readProductVersion(productVersionId, loadVocab) {
    //console.log("Executing readProductVersion for productVersionId = " + productVersionId + ".")
    if (!productVersionId) {
        console.log('Cannot readProductVersion if no productVersionId provided.');
        return;
    }
    const nextConfig = getConfig();
    let data = await fetcher(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/product/${productVersionId}?loadVocab=${loadVocab}`);
    return data;
}

async function retrieveCitation(productVersionId) {
    //console.log("Executing retrieveCitation for productVersionId = " + productVersionId + ".")
    if (!productVersionId) {
        console.log('Cannot retrieveCitation if no productVersionId provided.');
        return null;
    }
    const nextConfig = getConfig();
    const cslName = 'chicago-note-bibliography-16th-edition';
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/admin/product/${productVersionId}/citation?cslName=${cslName}`;
    NProgress.start();
    let apiResponse = await fetch(url, apiOptions('post', null));
    if (apiResponse.ok) {
        // console.log('api responded ok');
        NProgress.done();
        return await apiResponse.text();
    } else {
        NProgress.done();
        console.error('api response not ok');
    }
}

async function readVocabularies(value, apiPath) {
    console.log('Executing readVocabularies for value = ' + value + ' and apiPath = ' + apiPath + '.');
    const nextConfig = getConfig();
    let url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}${apiPath.replace('/api/v1', '')}`;
    if (value != '') {
        url += `?q=${value}`;
    }
    //console.log(url)
    let data = await fetcher(url);
    if (data) {
        return data.map(function (term) {
            if (apiPath.includes('milestoneType')) {
                return term;
            }
            return term.label;
        });
    } else {
        //console.log("api responded with zero results");
        return [];
    }
}

async function readVocabList(value, vocab, vocabularies, specialCase) {
    let vocabTerm = vocab.substring(vocab.lastIndexOf('/') + 1);
    if (!specialCase) {
        if (vocabularies && Object.keys(vocabularies).length > 0 && vocabularies[vocabTerm]) {
            return vocabularies[vocabTerm];
        }
    }
    if (!vocab.startsWith('/')) {
        vocab = '/' + vocab;
    }
    const nextConfig = getConfig();
    let url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}${vocab.replace('/api/v1', '')}`;
    if (value != '') {
        url += `?q=${value}`;
    }
    let data = await fetcher(url);
    if (data) {
        return data;
    } else return [];
}

async function getRelationships(value, ontology) {
    const nextConfig = getConfig();
    let url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/relationships/types/${ontology}`;
    if (value != '') {
        url += `?q=${value}`;
    }
    let data = await fetcher(url);
    return data ? data : [];
}

async function getProductByProductVersionId(productVersionId) {
    const nextConfig = getConfig();
    let data = await fetcher(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/product/${productVersionId}`);
    return data ? data : [];
}

async function getProductsByProductIdAndProductType(productId, productType) {
    const nextConfig = getConfig();
    let data = await fetcher(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/admin?productType=${productType}&productId=${productId}`);
    return data ? data : [];
}

async function createProductVersion(productType, program, study, knowledgeProductType, roundType, email) {
    const nextConfig = getConfig();
    let url = nextConfig.publicRuntimeConfig.apiProxyBasePath + '/products/admin/product?';
    url += 'productType=' + productType;
    url += '&owner=' + email;
    if (program && program !== null) {
        url += '&program=' + program;
    }
    if (study && study !== null) {
        url += '&study=' + study;
    }
    if (knowledgeProductType && knowledgeProductType !== null) {
        url += '&knowledgeProductType=' + knowledgeProductType;
    }
    if (roundType && roundType !== null) {
        url += '&roundType=' + roundType;
    }
    let data = await fetcher(url, apiOptions('post'));
    return data;
}

async function deleteProductVersion(productVersionId, sourcePath) {
    console.log('Executing deleteProductVersion for productVersionId = ' + productVersionId + '.');
    const nextConfig = getConfig();
    let data = await fetcher(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/admin/product/${productVersionId}`, apiOptions('delete'));
    if (data) Router.reload();
}

async function createNewVersion(productVersionId, type, sourcePath) {
    console.log('Executing createNewVersion for productVersionId = ' + productVersionId + '.');
    if (type == 'update' || type == 'patch') {
        const nextConfig = getConfig();
        let data = await fetcher(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/admin/product/${productVersionId}/createNewVersion?type=${type}`, apiOptions('post', null));
        return data;
    } else {
        return 'Update type not valid.';
    }
}

async function updateProductVersion(product) {
    console.log('Executing updateProductVersion for productVersionId = ' + product.productVersionId + '.');
    const nextConfig = getConfig();
    let apiResponse = await fetch(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/admin/product/${product.productVersionId}`, apiOptions('put', JSON.stringify(product)));
    return apiResponse;
}

async function uploadFile(file, productVersionId, properties, email) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('productVersionId', productVersionId);
    formData.append('properties', properties);
    const apiOptions = {
        mode: 'same-origin',
        method: 'post',
        body: formData,
    };
    const nextConfig = getConfig();
    let data = await fetcher(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/${productVersionId}/files/upload`, apiOptions);
    return data;
}

async function downloadFile(fileId, fileName, productVersionId, properties, email, productId) {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/${productVersionId}/files/${fileId}/download`;
    let propertiesMap = {};
    for (let val in properties) {
        propertiesMap[properties[val].field] = properties[val].value;
    }
    const { disclosure, sensitive } = propertiesMap;
    if (disclosure === 'true') {
        const err = "You can't download this file because it contains identifiable information.";
        return err;
    }
    if (sensitive === 'true') {
        const err = "You can't download this file because it contains sensitive information.";
        return err;
    }

    const res = await fetch(url);

    if (!res.ok) {
        const err = await res.text();
        return err;
    }
    let props = {};
    props['FILE_ID'] = fileId;
    props['FILE_NAME'] = fileName;
    props['PRODUCT_ID'] = productId;
    logEvent(productVersionId, email, `downloaded file; ` + fileName, 'mcc-manager', 'DOWNLOAD_FILE', props);
    const blob = await res.blob();
    const objUrl = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = objUrl;
    link.download = fileName;
    link.click();
    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
    }, 250);
}

async function changeWorkflowActions(productVersionId, workflowId, statusTransitionId, email, notes, statusTransition) {
    let formData = new FormData();
    formData.append('data', notes);
    formData.append('email', email);
    formData.append('transitionAction', statusTransition);
    //console.log(statusTransition)
    const apiOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'same-origin',
        method: 'post',
        body: JSON.stringify(statusTransition),
    };
    const nextConfig = getConfig();
    let data = await fetcher(
        `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products/${productVersionId}/workflows/${workflowId}/transitions/${statusTransitionId}?notes=${notes}&email=${email}`,
        apiOptions,
    );
    return data;
}

async function getUsersByGroup(groupLabel) {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/radius/users?groupLabel=` + groupLabel;
    let data = await fetcher(url);
    return data ? data[0] : null;
}

async function importVariables(file, schemaVersionId, email, sourcePath) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('schemaVersionId', schemaVersionId);
    formData.append('email', email);
    const apiOptions = {
        mode: 'same-origin',
        method: 'post',
        body: formData,
    };
    const nextConfig = getConfig();
    let data = await fetcher(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/admin/importVariables`, apiOptions);
    if (data) {
        Router.reload();
    }
}

async function getProducts(queryString) {
    const nextConfig = getConfig();
    let url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/products`;
    if (queryString && queryString !== null) {
        url += '?' + queryString;
    }
    let data = await fetcher(url);
    return data ? data : [];
}

async function reindexProducts() {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/admin/products/reindex`;
    let data = await fetcher(url, apiOptions('post'));
    return data;
}

async function addPublishedDates() {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/admin/products/add-published-dates`;
    let data = await fetcher(url, apiOptions('post'));
    return data;
}

async function addPublishedDate(productVersionId) {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/admin/products/${productVersionId}/add-published-date`;
    let data = await fetcher(url, apiOptions('post'));
    return data;
}

async function reindexProduct(productVersionId) {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/admin/products/${productVersionId}/reindex`;
    let data = await fetcher(url, apiOptions('post'));
    return data;
}

async function registerDoi(productVersionId) {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/admin/products/${productVersionId}/register-doi`;
    let data = await fetcher(url, apiOptions('post'));
    return data;
}

async function batchRegisterDoi() {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/admin/dois/register-doi`;
    let data = await fetcher(url, apiOptions('post'));
    return data;
}

async function reindexProductByProductId(productId, productType) {
    const nextConfig = getConfig();
    const url = `${nextConfig.publicRuntimeConfig.apiProxyBasePath}/admin/products/${productId}/type/${productType}/reindex`;
    let data = await fetcher(url, apiOptions('post'));
    return data;
}

function apiOptions(method, body) {
    return {
        mode: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        method: method,
        body: body,
    };
}

export {
    readProductVersion,
    retrieveCitation,
    readVocabularies,
    readVocabList,
    getRelationships,
    createProductVersion,
    deleteProductVersion,
    getUsersByGroup,
    updateProductVersion,
    getProducts,
    getProductByProductVersionId,
    getProductsByProductIdAndProductType,
    reindexProducts,
    reindexProduct,
    registerDoi,
    reindexProductByProductId,
    importVariables,
    changeWorkflowActions,
    createNewVersion,
    uploadFile,
    downloadFile,
    batchRegisterDoi,
    addPublishedDates,
    addPublishedDate,
};
